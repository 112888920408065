import React from 'react'
import { Link as RouterLink } from 'gatsby'
import { Facebook, Linkedin, Phone, Mail } from 'icons'

import {
  Section,
  Container,
  Row,
  Column,
  Flex,
  Box,
  Heading,
  Text,
  Link,
  Icon,
  Button,
} from 'components'
import { PhoneLink, EmailLink } from 'components/ContactLink'

const ContactButton = (props) => (
  <Button
    as="a"
    variant="outline"
    sx={{
      borderColor: 'white',
      color: 'white',
      maxWidth: 200,
      width: '100%',
      ml: ['auto', null, null, 0],
      px: [1, 3],
      textWrap: 'nowrap',
      whiteSpace: 'nowrap',
    }}
    ml="auto"
    py={1}
    {...props}
  />
)

const Footer = () => {
  return (
    <Section py={[3, 6]} paddingBottom={[11, 6]} bg="darkNavy100" color="white">
      <Container>
        <Row flexWrap="wrap">
          <Column
            size={[1, null, null, 'auto']}
            display="flex"
            alignItems="center"
          >
            <Heading.H4
              color="white"
              sx={{
                position: 'relative',
                '&:before, &:after': {
                  content: '""',
                  width: 2,
                  height: 4,
                  bg: 'white',
                  position: 'absolute',
                  left: '-3px',
                  top: '4px',
                },
                '&:after': {
                  top: '11px',
                },
              }}
            >
              Brauchen Sie Hilfe?
            </Heading.H4>
          </Column>

          <Column size={[1, null, null, 'auto']}>
            <Flex sx={{ my: [null, null, 2, 0] }}>
              <Flex
                sx={{
                  flexGrow: [1, null, null, 0],
                  flexShrink: 0,
                  alignItems: 'center',
                }}
              >
                <Icon source={Mail} size={16} />
                <Text
                  fontSize={1}
                  fontWeight="bold"
                  pl={2}
                  pr={4}
                  flexShrink={0}
                >
                  E-Mail
                </Text>
              </Flex>
              <EmailLink as={ContactButton} />
            </Flex>
          </Column>

          <Column size={[1, null, null, 'auto']}>
            <Flex sx={{ my: [null, null, 2, 0] }}>
              <Flex
                sx={{
                  flexGrow: [1, null, null, 0],
                  flexShrink: 0,
                  alignItems: 'center',
                }}
              >
                <Icon source={Phone} size={16} />
                <Text
                  fontSize={1}
                  pl={2}
                  pr={4}
                  fontWeight="bold"
                  flexShrink={0}
                >
                  Telefon
                </Text>
              </Flex>
              <PhoneLink as={ContactButton} />
            </Flex>
          </Column>
        </Row>

        <Box py={3} />

        <Row>
          <Column size={[1, 1 / 2]}>
            <Text fontSize={0}>
              Wir sind für Sie Montag bis Freitag von 9:00 – 18:00 Uhr
              erreichbar.
            </Text>
            <Box display={['block', 'none']} py={2} />
          </Column>
          <Column size={[1, 1 / 2]}>
            <Flex
              justifyContent={['center', 'flex-end']}
              flexWrap="wrap"
              mx={[-2, -4]}
              my={[0, -1]}
            >
              <Link as={RouterLink} variant="nav.footer" to="/glossar">
                Glossar
              </Link>
              <Link as={RouterLink} variant="nav.footer" to="/datenschutz">
                Datenschutz
              </Link>
              <Link as={RouterLink} variant="nav.footer" to="/impressum">
                Impressum
              </Link>
              {/* "Jobs" are hidden but left there for layout purposes (takes up space on mobile) */}
              <Link
                variant="nav.footer"
                sx={{
                  visibility: 'hidden',
                  display: ['block', 'none'],
                }}
              >
                Jobs
              </Link>
            </Flex>
          </Column>

          <Box width="100%" py={2} />

          <Column size={[1, 1 / 2]}>
            <Flex
              sx={{
                justifyContent: ['center', 'flex-start'],
                mx: [-2, -3],
              }}
            >
              <Link
                as={Link}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: 2,
                  mx: 1,
                  py: 2,
                  color: 'darkNavy40',

                  svg: { 'path, rect, circle': { fill: 'currentColor' } },
                }}
                href="https://facebook.com/bambusio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box as={Facebook} sx={{ width: 16, height: 16 }} />
              </Link>
              {/* <Link
                as={Link}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: 2,
                  mx: 1,
                  py: 2,
                  color: 'darkNavy40',

                  'svg': { 'path, rect, circle': { fill: 'currentColor' } },
                }}
                href="https://twitter.com"
                target="_blank"
              >
                <Box as={Twitter} sx={{ width: 16, height: 16 }} />
              </Link> */}
              <Link
                as={Link}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: 2,
                  mx: 1,
                  py: 2,
                  color: 'darkNavy40',

                  svg: { 'path, rect, circle': { fill: 'currentColor' } },
                }}
                href="https://linkedin.com/company/bambusio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box as={Linkedin} sx={{ width: 16, height: 16 }} />
              </Link>
              {/* <Link
                as={Link}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: 2,
                  mx: 1,
                  py: 2,
                  color: 'darkNavy40',

                  'svg': { 'path, rect, circle': { fill: 'currentColor' } },
                }}
                href="https://instagram.com"
                target="_blank"
              >
                <Box as={Instagram} sx={{ width: 16, height: 16 }} />
              </Link> */}
            </Flex>
          </Column>
        </Row>
      </Container>
    </Section>
  )
}

export default Footer
